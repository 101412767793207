import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../../util/authContext/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import StockingsLogo from '../../img/stockings-logo-green.png';
import GoogleG from '../../img/google-g.png';
import './Login.css';

export default function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login, loginWithGoogle } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);
            navigate('/');
        } catch (error) {
            switch (error.code) {
                case "auth/wrong-password":
                    setError('The password for this account is invalid');
                    break;
                case "auth/user-not-found":
                    setError('This email is not associated with an account');
                    break;
                default:
                    setError('Failed to log in');
                    break;
            }
        }

        setLoading(false);
    }

    async function loginGoogle() {
        try {
            setError('');
            setLoading(true);
            await loginWithGoogle();
            navigate('/');
        } catch (error) {
            console.log(error);
            switch (error.code) {
                default:
                    setError('Failed to log in');
                    break;
            }
        }

        setLoading(false);
    }

    return (
        <>
            <div className="welcome-banner-container mb-5">
                <img src={StockingsLogo} className="stockings-logo" alt="logo" />
                <div className="welcome-banner-header">
                    Welcome to <span className="font-green">Stockings</span>!
                </div>
                <div className="welcome-banner-subheading">
                    Where it's Christmas every day.
                </div>
            </div>
            <div className="w-100 mw-400">
                <Card className="bg-light-grey">
                    <Card.Body>
                        <h4 className="text-center mb-3 font-weight-bold font-green">Log In</h4>
                        <hr />
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" ref={emailRef} required />
                            </Form.Group>
                            <Form.Group id="password" className="mt-2">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" ref={passwordRef} required />
                            </Form.Group>
                            <Button disabled={loading} className="w-100 stockings-btn mt-3" type="submit">
                                Log in
                            </Button>
                        </Form>
                        <div className="w-100 text-center mt-3">
                            <Link to="/forgot-password" className="font-green">Forgot Password?</Link>
                        </div>
                        <Button disabled={loading} className="w-100 mt-3 stockings-btn-grey align-items-center justify-content-center d-flex" onClick={loginGoogle}>
                            <img src={GoogleG} className="google-g" alt="logo" />
                            Log in with Google
                        </Button>
                    </Card.Body>
                </Card>
            </div>
            {/* <div className="w-100 text-center mt-2">
                Not a member? <Link to="/signup" className="font-green">Sign up</Link>
            </div> */}
        </>
    )
}




// import React, { Component } from 'react';
// import './Login.css';
// import StockingsLogo from '../../img/stockings-logo-green.png';
// import GoogleG from '../../img/google-g.png';

// class Login extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {  }
//     }
//     render() { 
//         return (
//             <div className="welcome-container">
//                 <img src={StockingsLogo} className="stockings-logo" alt="logo" />
//                 <div className="welcome-banner-container">
//                     <div className="welcome-banner-header">
//                         Welcome to <span className="green">Stockings</span>!
//                     </div>
//                     <div className="welcome-banner-subheading">
//                         Where it's Christmas every day.
//                     </div>
//                 </div>
//                 <div className="welcome-login-container">
//                     <input type="text" placeholder="Email" className="login-email"/>
//                     <input type="text" placeholder="Password" className="login-password"/>
//                     <div className="stockings-btn login-signin">
//                         Sign in
//                     </div>
//                     <a className="login-signup green">
//                         Not a member? Sign up here.
//                     </a>
//                     <div className="stockings-btn login-google" onClick={this.googleAuth}>
//                         <img src={GoogleG} className="google-g" alt="logo" />
//                         Sign in with Google
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }
 
// export default Login;