import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../../util/authContext/AuthContext';
import { Link } from 'react-router-dom';
import StockingsLogo from '../../img/stockings-logo-green.png';

export default function ForgotPassword() {
    const emailRef = useRef();
    const { resetPassword } = useAuth();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetSent, setResetSent] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setMessage('');
            setError('');
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage(`A reset link has been sent to ${emailRef.current.value}. Please check your email to continue.`);
            setResetSent(true);
        } catch (error) {
            setError('The password reset service is currently unavailable. Please try again later.');
        }

        setLoading(false);
    }

    return (
        <>
            <div className="welcome-banner-container mb-5">
                <img src={StockingsLogo} className="stockings-logo" alt="logo" />
                <div className="welcome-banner-header">
                    Welcome to <span className="font-green">Stockings</span>!
                </div>
                <div className="welcome-banner-subheading">
                    Where it's Christmas every day.
                </div>
            </div>
            <div className="w-100 mw-400">
                <Card className="bg-light-grey">
                    <Card.Body>
                    <h4 className="text-center mb-3 font-weight-bold font-green">Reset Password</h4>
                        <hr />
                        {error && <Alert variant="danger">{error}</Alert>}
                        {message && <Alert variant="success">{message}</Alert>}
                        <Form onSubmit={handleSubmit} className={resetSent ? "d-none" : ""}>
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" ref={emailRef} required />
                            </Form.Group>
                            <Button disabled={loading} className="w-100 stockings-btn mt-3" type="submit">
                                Reset password
                            </Button>
                        </Form>
                        <div className="w-100 text-center mt-3">
                            <Link to="/login" className="font-green">Back to Login</Link>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            {/* <div className="w-100 text-center mt-2">
                Not a member? <Link to="/signup" className="font-green">Sign up</Link>
            </div> */}
        </>
    )
}