const axios = require("axios").default;

class api {
    invoke(data) {
        const method = data.method;
        
        switch (method) {
            case "post":
                return this.axiosPost(data);
            default:
                return this.axiosGet(data);
        }
    }

    axiosGet(data) {
        const endpoint = data.endpoint;
        const payload = data.payload;
        return axios.get(process.env.REACT_APP_TDA_HOSTNAME + endpoint, {
            params: payload,
            headers: {
                "Authorization": "Bearer 9M9FPmf6SvZlh3ox3KdOVCU69anl9hGd8zt/EEUaizeCMJPLk5W8W9YQ/1UEmrb/0z6kUK7V9PJ8AoJgB8rOHfBKo1PVt6QCNS+BBxCN7vHCeEYW/r5LJR+Ao/HqWwlTQroQxHEhpNvb1Ek56Ja8hT28fNoI32IfBN2iLRgoX+O018Dt21xH8Ir4ZUZHH7wGcjw35daU53AB+xLC74MbaxFOhhBDkRmrzvfdLW3hMTd6JT6uG2iaB0ZFHrNc3L9tG2T0l8+KV66XOl/N+y+lgThmLh6pftnP4UhKnCcKgQxLG61No8+PxIpREbYiQsCOnoZvh7iMPwLoDL22s/L8663cl0fEsP8AhXuqlwgyT3EOaEQjvf30qb2ti5PoJX3h779AO+z7VjJEC1sWo5DCtjecKeg0YKmtH1a/ZeXJpzD4NHBE7GBBAefgCgSLL9kXBIg9bxlMYLts5lexCRC/Ydwpn5g6aAG89lahuqUguWBmSuNwW9DZY5wagq70z1RoEJWPTBZRRA+R/04D6htHogrvlPhXprww1J8LLF6L0pApHBj7A100MQuG4LYrgoVi/JHHvlSn7gd9ujVEaSQW9YfB85DifX5E4zZC1gz67Aq/RmxHH9Ea6uEhrDduQp9CU0b9X/tPVJc4JawIPhr3hwZpMB87IulGGlWzjeNG4DlVbYeLK8TWkrpfxWj3V3P7edO5ItSBJL4NTsMrADe3mGp3aDJ5xfa2PEIoTW4h//NRdUZRc+t1Fn3FnR/rvJJAyBEf/sDlc05JnpiMBPahvxySJ8SlZlSogg32kXu3Kgt/iaHzK2+/h0JEHSG7bBk63Kop6elZjvuchL+0JmBP744QCqgMG6f19ODkbrvEzeyBiJnfTEYzqIKmKckyJemiD74NLs/Alz7FxpWcbMWfQD1kuNCe+M6ZzoYghB7z0s7dt3SMyYElFa8LhY1H8bahhX0v2dfB076Wwbbp39D43jj4flQCbun45Tr1f6uiF7drFyTGD+lxVEs2bolplqKSkTn9GDyfq5aI4eYMVIKLoF19ebq8wXuIZ0ETESEh/pejzeMw/WNHMzKz6s1EWYxyqKzSrgnDRByOziga8VnsTzAe3k1LkJW3Vn7Ct2HQWk/V3/q2g2HIng==212FD3x19z9sWBHDJACbC00B75E"
            }
        });
    }

    async axiosPost(data) {
        const endpoint = data.endpoint;
        const payload = data.payload;
        await axios.post(process.env.REACT_APP_TDA_HOSTNAME + endpoint, {
            params: {
                ...payload
            }
        }).then((response) => {
            return response;
        }).catch((error) => {
            console.error("Axios ERROR:", error);
        });
    }
}

const Api = new api();
export default Api;