import Api from "./Api";

class accountService {
    getAccount(accountId) {
        return Api.invoke({
            method: "get",
            endpoint: "/accounts/" + accountId,
            payload: {}
        });
    }
}

const AccountService = new accountService();
export default AccountService;