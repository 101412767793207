import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from './util/authContext/AuthContext';
import Home from './view/home/Home';
import Account from './view/account/Account';
import Login from './view/login/Login';
import SignUp from './view/signUp/SignUp';
import ForgotPassword from './view/forgotPassword/ForgotPassword';
import { Container } from 'react-bootstrap';
import PrivateRoute from './util/privateRoute/PrivateRoute';

function App() {
  return (
    <Container className="d-flex flex-column align-items-center justify-content-center span-height bg-grey">
        <Router>
          <AuthProvider>
            <Routes>
              <Route path='/' element={<PrivateRoute/>}>
                <Route path='/' element={<Home/>}/>
              </Route>
              <Route path='/account' element={<PrivateRoute/>}>
                <Route path='/account' element={<Account/>}/>
              </Route>
              <Route path="/login" element={<Login/>} />
              {/* <Route path="/signup" element={<SignUp/>} /> */}
              <Route path="/forgot-password" element={<ForgotPassword/>} />
            </Routes>
          </AuthProvider>
        </Router>
    </Container>
  );
}

export default App;
