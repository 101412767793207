import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const Firebase = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
});

export const FirebaseAuth = Firebase.auth();
export const FirebaseGoogleAuth = () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    return FirebaseAuth.signInWithPopup(googleProvider);
};
export default Firebase;