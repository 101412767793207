import React, { useState } from 'react';
import './Home.css';
import { Card, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../../util/authContext/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

export default function Home() {
    const[error, setError] = useState('');
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    async function handleLogout() {
        setError('');

        try {
            await logout();
            navigate('/login')
        } catch (error) {
            setError('Failed to log out')
        }
    }

    return (
        <>
            <div className="w-100 mw-400">
                <Card className="bg-light-grey">
                    <Card.Body>
                        <h4 className="text-center mb-3 font-weight-bold font-green">Home</h4>
                        <hr />
                        {error && <Alert variant="danger">{error}</Alert>}
                        <strong>Email:</strong> {currentUser.email}
                        <Link to="/account" className="btn btn-primary stockings-btn w-100 mt-3">My Account</Link>
                    </Card.Body>
                </Card>
            </div>
            <div className="w-100 text-center mt-2">
                <Button className="stockings-btn" variant="link" onClick={handleLogout}>Log out</Button>
            </div>
        </>
    );
}