import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import AccountService from "../../util/tdaServices/AccountService";

class AccountBalance extends Component {
    state = {
        balance: ""
    }

    componentDidMount() {
        AccountService.getAccount("279125790").then((response) => {
            this.setState({
                balance: response.data.securitiesAccount.currentBalances.liquidationValue
            });
        });
    }

    render() { 
        return (
            <Card className="bg-light-grey">
                <Card.Body>
                    Account Balance: {this.state.balance}
                </Card.Body>
            </Card>
        );
    }
}
 
export default AccountBalance;