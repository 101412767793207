import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../../util/authContext/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import AccountBalance from '../../components/marketUI/AccountBalance';

export default function Account() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { currentUser, updatePassword, updateEmail } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match');
        }

        const promises = [];
        setLoading(true);
        setError('');
        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value));
        }
        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value));
        }

        Promise.all(promises).then(() => {
            navigate('/');
        }).catch((error) => {
            console.log(error)
            switch (error.code) {
                case "auth/requires-recent-login":
                    setError('To reset the email, you must have recently logged in. Please log out and back in and try again.');
                    break;
                default:
                    setError('Failed to update account');
                    break;
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <div className="w-100 mw-400">
                <AccountBalance />
                <Card className="bg-light-grey">
                    <Card.Body>
                        <h4 className="text-center mb-3 font-weight-bold font-green">Update Account</h4>
                        <hr />
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" ref={emailRef} required defaultValue={currentUser.email} />
                            </Form.Group>
                            <Form.Group id="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" ref={passwordRef} placeholder="Leave blank to keep old password" />
                            </Form.Group>
                            <Form.Group id="password-confirm">
                                <Form.Label>Password Confirmation</Form.Label>
                                <Form.Control type="password" ref={passwordConfirmRef} placeholder="Leave blank to keep old password" />
                            </Form.Group>
                            <Button disabled={loading} className="w-100 stockings-btn" type="submit">
                                Update
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            <div className="w-100 text-center mt-2">
                <Link to="/" className="font-green">Return to Home</Link>
            </div>
        </>
    )
}
