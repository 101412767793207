import React, { useContext, useState, useEffect } from 'react';
import Firebase, { FirebaseAuth, FirebaseGoogleAuth } from '../firebase/Firebase';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    function signup(email, password) {
        return FirebaseAuth.createUserWithEmailAndPassword(email, password);
    }

    function login(email, password) {
        return FirebaseAuth.signInWithEmailAndPassword(email, password);
    }

    function loginWithGoogle() {
        return FirebaseGoogleAuth();
    }

    function logout() {
        return FirebaseAuth.signOut();
    }

    function resetPassword(email) {
        return FirebaseAuth.sendPasswordResetEmail(email);
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email);
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password);
    }

    useEffect(() => {
        const unsubscribe = FirebaseAuth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, []);
    
    const value = {
        currentUser,
        signup,
        login,
        loginWithGoogle,
        logout,
        resetPassword,
        updateEmail,
        updatePassword
    };
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
